<template>
    <div>
      <h1>Campaign Page</h1>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CampaignPage',
  };
  </script>
  
  <style scoped>
  /* Add styles here */
  </style>
  