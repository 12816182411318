import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from './axios';  // Import the configured Axios instance
import './assets/css/app.css';
import './assets/css/style.css';
import './assets/css/tailwind.css';
import './assets/js/ui/script.js';
import './assets/js/ui/simplebar';
import './assets/js/ui/uikit.min.js';




const app = createApp(App);
app.config.globalProperties.$axios = axios;  // Add Axios to global properties

app.use(router).mount('#app');
