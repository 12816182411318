<template>
    <div>
      <Header />
      <Sidebar />
      <main>
        <router-view />
      </main>
      <Footer />
    </div>
  </template>
  
  <script>
  import Header from '@/components/HeaderComponent.vue';
  import Sidebar from '@/components/SidebarComponent.vue';
  import Footer from '@/components/FooterComponent.vue';
  
  export default {
    components: {
      Header,
      Sidebar,
      Footer,
    },
  };
  </script>
  
  <style scoped>
  /* Add styles here */
  </style>
  