<template>
    <header>
      <h1>Header</h1>
    </header>
  </template>
  
  <script>
  export default {
    name: 'HeaderComponent',
  };
  </script>
  
  <style scoped>
  /* Add styles here */
  </style>
  