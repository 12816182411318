<template>
    <aside>
      <h1>Sidebar</h1>
    </aside>
  </template>
  
  <script>
  export default {
    name: 'SidebarComponent',
  };
  </script>
  
  <style scoped>
  /* Add styles here */
  </style>
  