<template>
    <footer>
      <h1>Footer</h1>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterComponent',
  };
  </script>
  
  <style scoped>
  /* Add styles here */
  </style>
  