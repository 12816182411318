<script>
export default {
  name: 'WelcomePage',
};
</script>

<template>
  <div class="min-h-screen flex items-center justify-center bg-gray-100">
    <div class="max-w-4xl w-full mx-auto px-4 sm:px-6 lg:px-8">
      <div class="bg-white shadow-2xl rounded-lg overflow-hidden">
        <div class="md:flex">
          <!-- Left side - Welcome message and branding -->
          <div class="md:w-1/2 bg-indigo-700 p-8 flex flex-col justify-center">
            <h2 class="text-3xl font-bold mb-4 ">Welcome to Postora</h2>
            <p class="text-indigo-100 mb-6">
              Manage your social media campaigns with ease. Join thousands of marketers who trust Postora for their campaign management needs.
            </p>
            <ul class="space-y-2 ">
              <li class="flex items-center">
                <svg class="h-5 w-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                </svg>
                Intuitive Dashboard
              </li>
              <li class="flex items-center">
                <svg class="h-5 w-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                </svg>
                Real-time Analytics
              </li>
              <li class="flex items-center">
                <svg class="h-5 w-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                </svg>
                Multi-platform Support
              </li>
            </ul>
          </div>
          
          <!-- Right side - Login and Registration forms -->
          <div class="md:w-1/2 p-8 bg-gray-50">
            <div class="text-center mb-8">
              <h3 class="text-2xl font-bold text-gray-800">Get Started</h3>
              <p class="text-gray-600 mt-2">Create an account or login to continue</p>
            </div>
            
            <div class="space-y-4">
              <router-link to="/auth/register" class="block">
                <button class="w-full bg-indigo-600 rounded-lg px-4 py-3 transition duration-300 ease-in-out hover:bg-indigo-700  border border-indigo-600  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50">
                  Register
                </button>
              </router-link>
              
              <router-link to="/auth/login" class="block">
                <button class="w-full bg-white text-indigo-600 rounded-lg px-4 py-3 transition duration-300 ease-in-out border border-indigo-600 hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50">
                  Login
                </button>
              </router-link>
            </div>
            
            <div class="mt-6 text-center text-sm">
              <p class="text-gray-600">
                By continuing, you agree to our
                <a href="#" class="text-indigo-600 hover:underline">Terms of Service</a>
                and
                <a href="#" class="text-indigo-600 hover:underline">Privacy Policy</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Add any additional custom styles here if needed */
</style>