import { createRouter, createWebHistory } from 'vue-router';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import AuthLayout from '@/layouts/AuthLayout.vue';
import HomePage from '@/pages/HomePage.vue';
import AboutPage from '@/pages/AboutPage.vue';
import LoginPage from '@/pages/LoginPage.vue';
import RegisterPage from '@/pages/RegisterPage.vue';
import ProfilePage from '@/pages/ProfilePage.vue';
import CampaignPage from '@/pages/CampaignPage.vue';
import CreateCampaignPage from '@/pages/CreateCampaignPage.vue';
import WelcomePage from '@/pages/WelcomePage.vue'; // Import the WelcomePage component

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = !!localStorage.getItem('token');
      if (!isAuthenticated) {
        next('/welcome');
      } else {
        next();
      }
    },
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: HomePage
      },
      {
        path: 'about',
        name: 'About',
        component: AboutPage
      },
      {
        path: 'profile',
        name: 'Profile',
        component: ProfilePage,
        meta: { requiresAuth: true }
      },
      {
        path: 'campaigns',
        name: 'Campaigns',
        component: CampaignPage,
        meta: { requiresAuth: true }
      },
      {
        path: 'campaigns/create',
        name: 'CreateCampaign',
        component: CreateCampaignPage,
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/auth',
    component: AuthLayout,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = !!localStorage.getItem('token');
      if (isAuthenticated) {
        next('/');
      } else {
        next();
      }
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: LoginPage
      },
      {
        path: 'register',
        name: 'Register',
        component: RegisterPage
      }
    ]
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: WelcomePage
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = !!localStorage.getItem('token');

  if (requiresAuth && !isAuthenticated) {
    next('/auth/login');
  } else {
    next();
  }
});

export default router;
