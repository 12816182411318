/* eslint-disable */

// Handle theme settings
document.addEventListener('DOMContentLoaded', function () {
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  });
  
  // Function to set theme
  function setTheme(theme) {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
      localStorage.theme = 'dark';
    } else if (theme === 'light') {
      document.documentElement.classList.remove('dark');
      localStorage.theme = 'light';
    } else {
      localStorage.removeItem('theme');
    }
  }
  
  // Add post upload image
  document.addEventListener('DOMContentLoaded', function () {
    const addPostUrl = document.getElementById('addPostUrl');
    if (addPostUrl) {
      addPostUrl.addEventListener('change', function () {
        if (this.files[0]) {
          var picture = new FileReader();
          picture.readAsDataURL(this.files[0]);
          picture.addEventListener('load', function (event) {
            document.getElementById('addPostImage').setAttribute('src', event.target.result);
            document.getElementById('addPostImage').style.display = 'block';
          });
        }
      });
    }
  
    // Create Status upload image
    const createStatusUrl = document.getElementById('createStatusUrl');
    if (createStatusUrl) {
      createStatusUrl.addEventListener('change', function () {
        if (this.files[0]) {
          var picture = new FileReader();
          picture.readAsDataURL(this.files[0]);
          picture.addEventListener('load', function (event) {
            document.getElementById('createStatusImage').setAttribute('src', event.target.result);
            document.getElementById('createStatusImage').style.display = 'block';
          });
        }
      });
    }
  
    // Create product upload image
    const createProductUrl = document.getElementById('createProductUrl');
    if (createProductUrl) {
      createProductUrl.addEventListener('change', function () {
        if (this.files[0]) {
          var picture = new FileReader();
          picture.readAsDataURL(this.files[0]);
          picture.addEventListener('load', function (event) {
            document.getElementById('createProductImage').setAttribute('src', event.target.result);
            document.getElementById('createProductImage').style.display = 'block';
          });
        }
      });
    }
  });
  