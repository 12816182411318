<template>
    <div>
      <main>
        <router-view />
      </main>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AuthLayout',
  };
  </script>
  
  <style scoped>
  /* Add styles here */
  </style>
  