<template>
    <div>
      <h1>Profile Page</h1>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ProfilePage',
  };
  </script>
  
  <style scoped>
  /* Add styles here */
  </style>
  