<script>
import GuestLayout from '@/layouts/GuestLayout.vue';
import ApplicationLogo from '@/components/ApplicationLogo.vue';

export default {
  components: {
    GuestLayout,
    ApplicationLogo
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
        remember: false,
        errors: {}
      }
    };
  },
  methods: {
    async login() {
      try {
        const response = await this.$axios.post('/auth/login', {
          email: this.form.email,
          password: this.form.password,
        });
        localStorage.setItem('token', response.data.token);
        this.$router.push('/profile');
      } catch (error) {
        if (error.response && error.response.data) {
          this.form.errors = error.response.data.errors || {};
        }
        console.error(error);
      }
    },
  },
};
</script>

<template>
  <GuestLayout>
    <div class="relative lg:w-[580px] md:w-96 w-full p-10 min-h-screen bg-white shadow-xl flex items-center pt-10 dark:bg-slate-900 z-10">
      <div class="w-full lg:max-w-sm mx-auto space-y-10" uk-scrollspy="target: > *; cls: uk-animation-scale-up; delay: 100 ;repeat: true">
        <!-- logo image-->
        <router-link to="/">
          <ApplicationLogo class="top-10 block h-9 w-auto fill-current text-gray-800"/>
        </router-link>

        <!-- title -->
        <div>
          <h2 class="text-2xl font-semibold mb-1.5"> Sign in to your account </h2>
          <p class="text-sm text-gray-700 font-normal">
            If you haven’t signed up yet. 
            <router-link to="/auth/register" class="text-blue-700">Register here!</router-link>
          </p>
        </div>

        <!-- form -->
        <form @submit.prevent="login" class="space-y-7 text-sm text-black font-medium dark:text-white" uk-scrollspy="target: > *; cls: uk-animation-scale-up; delay: 100 ;repeat: true">
          <!-- email -->
          <div>
            <label for="email" class="">Email address</label>
            <div class="mt-2.5">
              <input v-model="form.email" id="email" name="email" type="email" autofocus=""  placeholder="Email" required="" class="!w-full !rounded-lg !bg-transparent !shadow-sm !border-slate-200 dark:!border-slate-800 dark:!bg-white/5"> 
              <div v-if="form.errors.email" class="text-red-500 text-xs mt-1">{{ form.errors.email }}</div>
            </div>
          </div>

          <!-- password -->
          <div>
            <label for="password" class="">Password</label>
            <div class="mt-2.5">
              <input v-model="form.password" id="password" name="password" type="password" placeholder="***"  class="!w-full !rounded-lg !bg-transparent !shadow-sm !border-slate-200 dark:!border-slate-800 dark:!bg-white/5">  
              <div v-if="form.errors.password" class="text-red-500 text-xs mt-1">{{ form.errors.password }}</div>
            </div>
          </div>

          <div class="flex items-center justify-between">
            <div class="flex items-center gap-2.5">
              <input v-model="form.remember" id="rememberme" name="rememberme" type="checkbox">
              <label for="rememberme" class="font-normal">Remember me</label>
            </div>
            <router-link to="/auth/forgot-password" class="text-blue-700">Forgot password</router-link>
          </div>

          <!-- submit button -->
          <div>
            <button type="submit" class="button bg-primary text-white w-full">Sign in</button>
          </div>

          <div class="text-center flex items-center gap-6"> 
            <hr class="flex-1 border-slate-200 dark:border-slate-800"> 
            Or continue with  
            <hr class="flex-1 border-slate-200 dark:border-slate-800">
          </div> 

          <!-- social login -->
          <div class="flex gap-2" uk-scrollspy="target: > *; cls: uk-animation-scale-up; delay: 400 ;repeat: true">
            <a href="#" class="button flex-1 flex items-center gap-2 bg-primary text-white text-sm"> 
              <ion-icon name="logo-facebook" class="text-lg"></ion-icon> facebook  
            </a>
            <a href="#" class="button flex-1 flex items-center gap-2 bg-sky-600 text-white text-sm"> 
              <ion-icon name="logo-twitter"></ion-icon> twitter  
            </a>
            <a href="#" class="button flex-1 flex items-center gap-2 bg-black text-white text-sm"> 
              <ion-icon name="logo-github"></ion-icon> github  
            </a>
          </div>
        </form>
      </div>
    </div>
  </GuestLayout>
</template>

<style scoped>
/* Add styles here */
</style>
